@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Open Sans', sans-serif !important;
  background-color: #f7fbff;
  margin: 0 auto;
  position: absolute;
  min-height: 100%;
  width: 100%;
}

.ql-container {
  min-height: 300px;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-up {
  @apply animate-[slideUp_0.5s_ease-in-out];
}
